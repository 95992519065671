@tailwind base;
@tailwind components;
@tailwind utilities;
/* index.css or App.css */
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;500;600;700;800&display=swap');

body {
  margin: 0;
  background-color: #b5b5b5;
  font-family: 'Inconsolata', monospace;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
